import { gql } from '@apollo/client';

export const getAllSymptoms = gql`
  query {
    medicalDepartments {
      id
      name
      ageLimit {
        min
        max
      }
      genderLimit
      questionNum
      createdAt
    }
  }
`;

export const getSymptom = gql`
  query($id: ID!) {
    medicalDepartment(id: $id) {
      id
      name
      ageLimit {
        min
        max
      }
      genderLimit
      questionNum
      createdAt
    }
  }
`;

export const validDuplicated = gql`
  query($symptomId: ID!) {
    ongoingTreatment(departmentId: $symptomId) {
      id
      status
    }
  }
`;

export const validDuplicatedWithMe = gql`
  query($symptomId: ID!) {
    ongoingTreatment(departmentId: $symptomId) {
      id
      status
    }
    me {
      id
      email
      provider
      phonenum
      name
      gender
      ageGroup
      agreeMarketingInform
      referralCode
      birthDate
    }
  }
`;

export const getQuestion = gql`
  query($symptomId: ID!, $order: Int) {
    medicalQuestion(departmentId: $symptomId, order: $order) {
      id
      title
      order
      description
      type {
        id
        name
        description
      }
      required
      options {
        id
        title
        description
        imageUrl
        order
        type {
          id
          name
          description
        }
      }
    }
  }
`;

export const SUBMIT_QUESTION_ANSWER = gql`
  mutation($input: SubmitQuestionAnswerInput!) {
    submitQuestionAnswer(input: $input) {
      nextQuestion {
        id
        title
        order
        description
        type {
          id
          name
          description
          __typename
        }
        required
        options {
          id
          title
          description
          imageUrl
          order
          type {
            id
            name
            description
            __typename
          }
          __typename
        }
      }
    }
  }
`;

export const CREATE_QUESTION_PAPER = gql`
  mutation($input: CreateQuestionPaperInput!) {
    createQuestionPaper(input: $input) {
      id
      answers {
        id
        question {
          id
        }
        value
      }
    }
  }
`;

export const GET_PAYMENTS = gql`
  query {
    userPaymentMethods {
      id
      cardName
      cardNum
      createdAt
    }
  }
`;

export const DELETE_USER_PAYMENT_METHOD = gql`
  mutation($id: ID!) {
    deleteUserPaymentMethod(id: $id) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const SUBMIT_PATIENT_INFO = gql`
  mutation($input: SubmitPatientInformationInput) {
    submitPatientInformation(input: $input) {
      id
      status
      prescription {
        id
        url
        hospitalId
        userId
        treatmentId
        expirationDate
        createdAt
        updatedAt
      }
      patientInformation {
        id
        rrn
        phonenum
        gender
      }
      medicalQuestionPaper {
        id
        answers {
          id
        }
      }
      department {
        id
        name
      }
      paymentMethod {
        cardName
      }
    }
  }
`;

export const GET_TREATMENT = gql`
  query($id: ID!) {
    treatment(id: $id) {
      id
      status
      statusHistory {
        id
        status
        createdAt
      }
      prescription {
        id
        url
        hospitalId
        userId
        treatmentId
        expirationDate
      }
      department {
        id
        name
      }
      paymentMethod {
        id
        cardName
        cardNum
        createdAt
      }
      delivery {
        id
        message
        address1
        address2
        postCode
        invoiceNo
        status
        createdAt
        updatedAt
      }
      hospitalCare {
        id
        expenses
        hospital {
          id
          name
          address
          phones
          tel
          introduction
          treatmentPriceInfo
        }
        doctor {
          id
          name
          profileImgUrl
        }
      }
      hospitalCareOrder {
        id
        totalAmount
        deliveryAmount
        deliveryDiscountAmount
        lines {
          id
          product {
            name
          }
          amount
          discountAmount
          totalAmount
          quantity
        }
        payment {
          id
          cardReceiptUrl
        }
      }
      pharmacyCare {
        id
        price
        pickupType
        isGenericSubstitution
        prescription {
          id
        }
        delivery {
          id
        }
        medicineInformation
        pharmacy {
          id
          name
          tel
          fax
          address
          lat
          lon
          gunguId
          createdAt
          updatedAt
        }
      }
      pharmacyCareOrder {
        id
        totalAmount
        deliveryAmount
        deliveryDiscountAmount
        createdAt
        updatedAt
        lines {
          id
          product {
            name
          }
          amount
          discountAmount
          totalAmount
        }
        payment {
          id
          cardReceiptUrl
          createdAt
          updatedAt
        }
      }
      createdAt
      currentHospitalMatching {
        id
        hospital {
          id
          name
          address
          phones
          tel
          introduction
          treatmentPriceInfo
          doctor {
            id
            name
            profileImgUrl
            briefHistory
            createdAt
            updatedAt
          }
        }
        isCanceled
        createdAt
        updatedAt
      }
      canceledMatchingCount
      user {
        id
        name
        phonenum
        birthDate
      }
      cancelType
      cancelReason
      medicalQuestionPaper {
        id
        createdAt
        answers {
          id
          question {
            id
            title
          }
          value
        }
      }
    }
  }
`;

export const GET_TREATMENT_SUMMARY = gql`
  query($id: ID!) {
    treatment(id: $id) {
      id
      status
      department {
        id
        name
      }
      paymentMethod {
        id
        cardName
      }
      delivery {
        id
        status
        invoiceNo
      }
      hospitalCare {
        id
        expenses
      }
      medicalQuestionPaper {
        id
      }
    }
  }
`;

export const GET_TREATMENT_ORDER = gql`
  query($id: ID!) {
    treatment(id: $id) {
      id
      status
      prescription {
        expirationDate
      }
      pharmacyCareOrder {
        id
        totalAmount
        payment {
          id
        }
      }
      hospitalCareOrder {
        id # 주문 번호
        totalAmount # 총액
        # deliveryAmount
        # deliveryDiscountAmount
        # delivery {
        #   id
        # }
        createdAt
        updatedAt
        lines {
          id
          product {
            id
            name
            type
          }
          amount
          discountAmount
          totalAmount
          quantity
        }
        payment {
          id
        }
      }
    }
    me {
      id
      email
      provider
      phonenum
      name
      gender
      ageGroup
      agreeMarketingInform
      referralCode
      birthDate
    }
  }
`;

export const GET_TREATMENTS = gql`
  query {
    treatments {
      id
      status
      department {
        id
        name
      }
      updatedAt
      createdAt
    }
  }
`;

export const GET_LATEST_TREATMENT = gql`
  query {
    latestTreatment {
      id
      status
      updatedAt
      createdAt
      department {
        id
        name
      }
    }
  }
`;

export const GET_USER_ADDRESSES = gql`
  query {
    userAddresses {
      id
      address1
      address2
      postCode
    }
  }
`;

export const GET_SI_LOCATIONS = gql`
  query($filter: SiFilter) {
    siLocations(filter: $filter) {
      id
      siName
      gunguList {
        id
        gunguName
      }
    }
  }
`;

export const GET_PHARMACIES = gql`
  query($pagination: PaginationInput, $filter: PharmacyListFiler) {
    pharmacies(pagination: $pagination, filter: $filter) {
      data {
        id
        name
        tel
        fax
        address
        lat
        lon
        gunguId
        createdAt
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const RECEIVE_DONE = gql`
  mutation($treatmentId: ID!) {
    completeTreatment(treatmentId: $treatmentId) {
      id
      status
      statusHistory {
        id
        status
        createdAt
      }
    }
  }
`;

export const UPDATE_PHARMACY_INFORMATION = gql`
  mutation($input: UpdatePharmacyInformationInput!) {
    updatePharmacyInformation(input: $input) {
      id
      status
      statusHistory {
        id
        status
        createdAt
      }
    }
  }
`;

export const CREATE_PHARMACY_CARE_PAYMENT = gql`
  mutation($input: PharmacyCarePaymentInput!) {
    createPharmacyCarePayment(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const SUBMIT_PHARMACY_INFORMATION = gql`
  mutation($input: SubmitPharmacyInformationInput!) {
    submitPharmacyInformation(input: $input) {
      id
      status
      statusHistory {
        id
        status
        createdAt
      }
    }
  }
`;

export const CREAT_USER_ADDRESS = gql`
  mutation($input: CreateUserAddressInput) {
    createUserAddress(input: $input) {
      id
      address1
      address2
      postCode
    }
  }
`;

export const APPLY_TREATMENT = gql`
  mutation($input: ApplyTreatmentInput!) {
    applyTreatment(input: $input) {
      id
      status
      prescription {
        id
        url
        hospitalId
        userId
        treatmentId
        expirationDate
        createdAt
        updatedAt
      }
      patientInformation {
        id
        rrn
        phonenum
        gender
      }
      medicalQuestionPaper {
        id
        answers {
          id
        }
      }
      department {
        id
        name
      }
      paymentMethod {
        cardName
      }
    }
  }
`;

//medicalDepartmentId: ID!
// paperId: ID!
// rrn: String!
// name: String
// phonenum: String
// paymentMethodId: ID!
export const CREATE_TREATMENT = gql`
  mutation($input: CreateTreatmentInput!) {
    createTreatment(input: $input) {
      id
    }
  }
`;

export const REFUSE_MATCHING = gql`
  mutation($treatmentId: ID!) {
    cancelTreatmentMatching(treatmentId: $treatmentId) {
      id
    }
  }
`;

export const CANCEL_TREATMENT = gql`
  mutation($input: CancelTreatmentInput!) {
    cancelTreatment(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const getOfferItem = gql`
  query($id: ID!) {
    offer(id: $id) {
      id
      startDate
      endDate
      title
      description
      title2
      description2
      minPrice
      maxPrice

      recentOfferUsers {
        id
        username
        userPhonenum
        price
      }
      hospital {
        id
      }
      winnerNum
      applyViewCount
      applyAveragePrice
      contentsImageUrls
      announcementDate
      createdAt
      updatedAt
    }
  }
`;

// for getStaticPaths (offer/[id]/deal )
export const getOfferIdList = gql`
  query {
    offers {
      id
    }
  }
`;

export const getOfferList = gql`
  query {
    offers {
      id
      startDate
      endDate
      title
      description
      title2
      description2
      minPrice
      maxPrice

      recentOfferUsers {
        id
        username
        userPhonenum
        price
      }
      hospital {
        id
      }
      winnerNum
      applyViewCount
      applyAveragePrice
      contentsImageUrls
      announcementDate
      createdAt
      updatedAt
    }
  }
`;

export const APPLY_OFFER = gql`
  mutation ApplyOffer($input: OfferInput!) {
    applyOffer(input: $input) {
      id
      offer {
        id
        maxPrice
      }
      user {
        id
        name
      }
    }
  }
`;
