import { gql } from '@apollo/client';

export const getOfferItem = gql`
  query($id: ID!) {
    offer(id: $id) {
      id
      startDate
      endDate
      title
      description
      title2
      description2
      minPrice
      maxPrice

      recentOfferUsers {
        id
        username
        userPhonenum
        price
      }
      hospital {
        id
      }
      winnerNum
      applyViewCount
      applyAveragePrice
      contentsImageUrls
      announcementDate
      createdAt
      updatedAt
    }
  }
`;

// for getStaticPaths (offer/[id]/deal )
export const getOfferIdList = gql`
  query {
    offers {
      id
    }
  }
`;

export const getOfferList = gql`
  query {
    offers {
      id
      startDate
      endDate
      title
      description
      title2
      description2
      minPrice
      maxPrice

      recentOfferUsers {
        id
        username
        userPhonenum
        price
      }
      hospital {
        id
      }
      winnerNum
      applyViewCount
      applyAveragePrice
      contentsImageUrls
      announcementDate
      createdAt
      updatedAt
    }
  }
`;

export const APPLY_OFFER = gql`
  mutation ApplyOffer($input: OfferInput!) {
    applyOffer(input: $input) {
      id
      offer {
        id
        maxPrice
      }
      user {
        id
        name
      }
    }
  }
`;
